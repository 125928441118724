// Doc ready

const ready = (callback) => {

	if (document.readyState != 'loading') callback();
	else document.addEventListener('DOMContentLoaded', callback);

}

ready(() => {


	// BACK TO TOP + HEADER

	const topButton			= document.getElementById('top');
	const header			= document.getElementById('header-nav');
	const navbarCollapse	= document.getElementById('navbarCollapse');

	window.onscroll = function () {

		scrollFunction();

	};

	function scrollFunction() {

		let headerHeight = header.offsetHeight;

		if (
			document.body.scrollTop > headerHeight ||
			document.documentElement.scrollTop > headerHeight
			) {

			topButton.style.display = 'block';
			header.classList.add('header-dark');

		} else {

			topButton.style.display = 'none';

			if (navbarCollapse.classList.contains('show')) {

				header.classList.add('header-dark');

			} else {

				header.classList.remove('header-dark');

			}
			
		}
	}

	// When the user clicks on the button, scroll to the top of the document
	topButton.addEventListener('click', backToTop);

	function backToTop() {

		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;

	}


	// Navbar toggler background

	navbarCollapse.addEventListener('show.bs.collapse', function () {

		header.classList.add('header-dark');
		
	});

	navbarCollapse.addEventListener('hide.bs.collapse', function () {

		let headerHeight = header.offsetHeight;

		if (
			document.body.scrollTop > headerHeight ||
			document.documentElement.scrollTop > headerHeight
			) {

			header.classList.add('header-dark');

		} else {

			header.classList.remove('header-dark');

		}
	});

	
	// SCROLLSPY

	let homevar = document.getElementById('navhome');

	if(homevar) {
		let scrollSpy = new bootstrap.ScrollSpy(document.body, {
			target: '#navbarCollapse',
			offset: header.offsetHeight + 1
		})
	}



	// SMOOTH SCROLL TO ANCHORS

	const anchors = document.querySelectorAll('a[href^="#"]:not([href="#"], [href^="#collapseProgram"])');

	anchors.forEach(trigger => {

		trigger.onclick = function(e) {

			e.preventDefault();

			let hash				= this.getAttribute('href');
			let target				= document.querySelector(hash);
			let headerOffset		= header.offsetHeight;
			let elementPosition		= target.offsetTop;
			let offsetPosition		= elementPosition - headerOffset;

			window.scrollTo({

				top: offsetPosition,
				behavior: 'smooth'

			});
		};
	});


	// Intersection Observer API for animations

	const observedElements = document.querySelectorAll('[class*="animate-"], [class*=" animate-"]');

	const observerOptions = {
		threshold: 1,
		rootMargin: '0px'
	};

	const observer = new IntersectionObserver(entries => {

		entries.forEach(entry => {

			if (entry.isIntersecting) {

				let classList = entry.target.className.split(/\s+/);

				for (let i = 0; i < classList.length; i++) {

				    if (classList[i].includes("animate-") ) {

				    	let changedclass = classList[i].replace('animate-', 'animated-');

				    	entry.target.classList.replace(classList[i], changedclass);

				    }

				}

				observer.unobserve(entry.target);

			} else {
				// entry not Intersecting
			}

		});

	}, observerOptions);

	observedElements.forEach(element => {
		observer.observe(element)
	});


	// Modals

	let bioModal = document.getElementById('bioModal');

	bioModal.addEventListener('show.bs.modal', function (event) {

		let button 					= event.relatedTarget;

		let name 					= button.previousElementSibling.previousElementSibling.previousElementSibling.textContent;
		let pic 					= button.parentElement.previousElementSibling.src;
		let bio 					= button.getAttribute('data-bs-bio');

		let modalTitle 				= bioModal.querySelector('.modal-title');
		let modalPic 				= bioModal.querySelector('.modal-pic');
		let modalContent			= bioModal.querySelector('.modal-body');

		modalTitle.textContent		= name;
		modalPic.src				= pic;
		modalContent.textContent	= bio;

	})


});



// JQuery

jQuery.noConflict()(function($) {

	"use strict";
	
	$(document).ready(function() {

		// Owl carousel init
		$('#owl-carousel-brands').owlCarousel({
			autoplay: true,
			loop: true,
			lazyLoad: true,
			margin: 50,
			stagePadding: 0,
			dots: true,
			responsiveClass: true,
			responsive: {
				0: {
					items: 2
				},
				768: {
					items: 3
				},
				992: {
					items: 4
				}
			}
		});

	});

});